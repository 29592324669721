import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAttributes from "../../../../hooks/useAttributes";
import { exportData } from "../../../../store/MappedEDPs/mappedEPDSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { DialogHeader } from "../../Common/dialogHeader";
import TableActionButton from "../../Common/tableActionButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExportEPDs = (props) => {
  const dispatch = useDispatch();
  const { attributes, attributesList } = useAttributes();
  const downloadRef = useRef(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const { exportLoading } = useSelector(({ mappedEPD }) => mappedEPD.slice);

  const toggleExportModal = () => {
    setShowExportModal((prev) => !prev);
  };
  const closeExportModal = () => {
    setSelectedColumns([]);
    setShowExportModal(false);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const currentSelection = [...selectedColumns];
    const index = currentSelection.findIndex((f) => f === value);
    if (index > -1) {
      if (!checked) {
        currentSelection.splice(index, 1);
      }
    } else {
      if (checked) {
        currentSelection.push(value);
      }
    }
    setSelectedColumns(currentSelection);
  };
  const handleExport = async () => {
    try {
      const params = new URLSearchParams();
      const res = await dispatch(exportData(selectedColumns));
      if (res && typeof res === "string" && res !== "error") {
        if (downloadRef && downloadRef.current) {
          downloadRef.current.href = res;
          downloadRef.current.download = `Extracted EPDs ${new Date().toLocaleString()}`;
          downloadRef.current.click();
          dispatch(showMessage({ message: "Exported successfully" }));
        }
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred!",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    }
  };
  const onValuesChange = (e, values) => {
    setSelectedColumns(values);
  };

  return (
    <>
      <Button onClick={toggleExportModal} variant="contained" color="primary">
        Export
      </Button>
      {showExportModal && (
        <Dialog
          open={showExportModal}
          maxWidth="xl"
          fullWidth
          scroll="body"
          onClose={closeExportModal}>
          <DialogHeader onClose={closeExportModal} title={"Export EPDs"} />
          <div>
            <Box mb={2}>
              <Autocomplete
                disableCloseOnSelect
                multiple
                id="attributesOptions"
                options={attributesList}
                getOptionLabel={(option) => option}
                onChange={onValuesChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Attributes"
                  />
                )}
              />
              {/* <FormGroup row>
              {attributesList.map((m) => (
                <FormControlLabel
                  key={m.id}
                  control={
                    <Checkbox
                      disabled={exportLoading}
                      color="primary"
                      value={m}
                      onChange={handleChange}
                    />
                  }
                  label={m}
                />
              ))}
            </FormGroup> */}
            </Box>
            <a ref={downloadRef} target="_blank" />
            <Box textAlign="right">
              <TableActionButton
                onClick={handleExport}
                disabled={exportLoading || !Boolean(selectedColumns.length)}
                loading={exportLoading}
                title="Export Data"
              />
            </Box>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ExportEPDs;
