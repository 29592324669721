import React, { useContext } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import UserContext from "../context/UserContext";
import { I18nPropvider, LOCALES } from "../i18nProvider";

import RevaluNotification from "../common/notification/NotificationCard";
import Login from "./Login/login";

import { Box } from "@mui/material";
import { RouteLinks } from "../common/Constants/routes";
import { DrawerHeader } from "../common/Sidebar/drawerHeader.js";
import Sidebar from "../common/Sidebar/sidebar.js";
import { getSession } from "../modules/AuthLibrary";
import AllContacts from "./Dashboard/Contacts/contacts";
import CustomMaterial from "./Dashboard/CustomMaterial/customMaterialEPDs";
import MappedEPDs from "./Dashboard/EDPManagement/MappedEPDs/mappedEPDs";
import EcoPortalEPDs from "./Dashboard/EDPManagement/OriginalEDPs/EcoPortalEPDs/ecoportalEPDs";
import DashboardLanding from "./Dashboard/Landing/landing.js";
import AllManufactureUsers from "./Dashboard/Manufacturer/manufactures";
import ProductNews from "./Dashboard/ProductNews/productNews";
import AllSupplier from "./Dashboard/Supplier/supplier";
import AllAdminUsers from "./Dashboard/Users/viewAllAdminUsers";
import viewAllRegisteredUsers from "./Dashboard/Users/viewAllRegisteredUsers";

const ProtectedRouteLayout = ({ Component }) => {
  const authToken = getSession();
  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, pt: 1, pl: 1.5, mb: 7, pr: 2 }}>
        <DrawerHeader />
        <Component />
      </Box>
    </>
  );
};

const App = () => {
  const { selectedTheme } = useContext(UserContext);
  return (
    <>
      <I18nPropvider locale={LOCALES.ENGLISH}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={selectedTheme}>
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Routes>
                  <Route path={RouteLinks.login} element={<Login />} />
                  {/* <Route path={RouteLinks.user.default} component={Dashboard} /> */}
                  {/* Protected Routes */}
                  <Route
                    path={RouteLinks.homePage}
                    element={
                      <ProtectedRouteLayout Component={DashboardLanding} />
                    }
                  />
                  <Route
                    path={RouteLinks.productNews}
                    element={<ProtectedRouteLayout Component={ProductNews} />}
                  />
                  <Route
                    path={RouteLinks.user.adminUsers}
                    element={<ProtectedRouteLayout Component={AllAdminUsers} />}
                  />
                  <Route
                    path={RouteLinks.user.platformUsers}
                    element={
                      <ProtectedRouteLayout
                        Component={viewAllRegisteredUsers}
                      />
                    }
                  />
                  <Route
                    path={RouteLinks.lca.ecoPortalEDPs}
                    element={<ProtectedRouteLayout Component={EcoPortalEPDs} />}
                  />
                  <Route
                    path={RouteLinks.lca.mappedEPDs}
                    element={<ProtectedRouteLayout Component={MappedEPDs} />}
                  />

                  {/* Manufacturer Routes */}
                  <Route
                    path={RouteLinks.manufacturer.all}
                    element={
                      <ProtectedRouteLayout Component={AllManufactureUsers} />
                    }
                  />
                  <Route
                    path={RouteLinks.manufacturer.supplier}
                    element={<ProtectedRouteLayout Component={AllSupplier} />}
                  />
                  <Route
                    path={RouteLinks.manufacturer.contacts}
                    element={<ProtectedRouteLayout Component={AllContacts} />}
                  />

                  {/* User Material Upload */}
                  <Route
                    path={RouteLinks.user.customMaterial}
                    element={
                      <ProtectedRouteLayout Component={CustomMaterial} />
                    }
                  />
                  {/* Todo this should lead to a 404 page */}
                  <Route path="*" element={<Login />} />

                  {/* <PrivateRoute
                  granted={authToken ? true : false}
                  exact
                  path={RouteLinks.user.default}
                  component={Dashboard}
                /> */}
                </Routes>
                {/* <Navigate to={RouteLinks.user.default} /> */}
                <RevaluNotification />
              </Box>
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </I18nPropvider>
    </>
  );
};

export default App;
