import React from "react";
import { Button, CircularProgress } from "@mui/material";

const TableActionButton = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  title,
  color = "primary",
  icon = undefined,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      startIcon={icon !== undefined ? icon : null}
      color={color}>
      {loading ? (
        <>
          <CircularProgress size={20} color="primary" />
          &nbsp; Please wait
        </>
      ) : (
        title
      )}
    </Button>
  );
};
export default TableActionButton;
